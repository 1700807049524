<template>
  <div class="elv-dashboard-bar-chart-container">
    <div class="elv-dashboard-bar-chart-header">
      <div class="elv-dashboard-bar-chart-header__title">
        {{ props.type === 'Balance' ? t('common.balance') : t('menus.transactions') }}
      </div>
      <el-radio-group v-model="rangeCurrent" class="elv-chart-tabs" @change="handleRangeClick">
        <el-radio-button label="7D" plain></el-radio-button>
        <el-radio-button label="30D" plain></el-radio-button>
        <el-radio-button label="90D" plain></el-radio-button>
        <el-radio-button label="180D" plain></el-radio-button>
      </el-radio-group>
    </div>
    <div :id="`elv-dashboard-bar-chart-content-chart__${props.type}`" class="elv-dashboard-bar-chart-content-chart">
      <div :id="`bar-chart-${props.type}`" />
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import echarts from '@/plugins/echarts'
import { minBy, every } from 'lodash-es'
import { useReportStore } from '@/stores/modules/reports/index'
import waterMark from '@/assets/img/project/dashboard-watermark.png'

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  guideStep: {
    type: Number,
    required: true
  },
  marketValueList: {
    type: Array,
    default: () => []
  },
  transactionList: {
    type: Object,
    default: () => {}
  }
})

const { t } = useI18n()
// const route = useRoute()
const reportStore = useReportStore()
const { entityDetail } = storeToRefs(reportStore)

const range = ref(90)
const rangeCurrent = ref('90D')
let chart: echarts.ECharts | null = null

const unit = computed(() => {
  return entityDetail.value?.unit ? entityDetail.value?.unit.toLocaleLowerCase() : 'millions'
})

const option = computed(() => {
  let xAxisData: Array<String> = []
  let yAxisData: any = []
  let series: any = []
  let chartColor: string[] = []
  let isEmpty: boolean = false
  if (props.type === 'Balance' && props.marketValueList.length > 0) {
    let serieData = []
    let dataList = []
    chartColor = ['#1753EB']
    const totalCount = props.marketValueList.length
    if (range.value >= totalCount) {
      dataList = props.marketValueList
    } else {
      dataList = props.marketValueList.slice(totalCount - range.value, totalCount)
    }
    xAxisData = dataList.map((item: any) => item.date)
    serieData = dataList.map((item: any) => {
      return { value: utils.numDrCrHandle(unit, item.value), ...item }
    })
    const minValueObj = minBy(serieData, 'value')
    console.log(minValueObj)
    let minValue = minValueObj?.value
    if (
      (minValueObj?.value > 0 && minValueObj?.value < 10) ||
      (minValueObj?.value < 0 && minValueObj?.value > -10) ||
      minValueObj?.value === 0
    ) {
      minValue = 0
    } else {
      // eslint-disable-next-line no-unsafe-optional-chaining
      minValue = minValueObj?.value > 0 ? minValueObj?.value - 1000 : minValueObj?.value + 1000
    }
    series.push({
      name: t('common.balance'),
      data: serieData,
      type: 'bar',
      areaStyle: { opacity: 0.1 },
      smooth: false,
      emphasis: { focus: 'series' },
      barMaxWidth: 40,
      showSymbol: false
    })
    yAxisData.push({
      type: 'value',
      scale: true,
      offset: 10,
      min: minValue.toFixed(0),
      axisLabel: {
        show: true
      }
    })
  } else if (
    props.type === 'Transactions' &&
    props.transactionList?.inflow?.items?.length > 0 &&
    props.transactionList?.outflow?.items?.length > 0
  ) {
    chartColor = ['#83AE00', '#1753EB']
    let inflowDataList = []
    let outflowDataList = []
    let inSerieData = []
    let outSerieData = []
    let inflowMinValueObj: any = {}
    let inflowMinValue: any = 0
    let outflowMinValueObj: any = {}
    let outflowMinValue: any = 0
    const inflowCount = props.transactionList?.inflow?.items?.length
    const outflowCount = props.transactionList?.outflow?.items?.length
    if (range.value >= inflowCount) {
      inflowDataList = props.transactionList?.inflow?.items
    } else {
      inflowDataList = props.transactionList?.inflow?.items?.slice(inflowCount - range.value, inflowCount)
    }
    xAxisData = inflowDataList.map((item: any) => item.date)
    inSerieData = inflowDataList.map((item: any) => {
      return { value: item.value, ...item }
    })
    inflowMinValueObj = minBy(inSerieData, 'value')
    // eslint-disable-next-line no-unsafe-optional-chaining, no-unused-vars
    inflowMinValue = inflowMinValueObj?.value > 0 ? inflowMinValue?.value - 2 : inflowMinValueObj?.value + 2
    if (range.value >= outflowCount) {
      outflowDataList = props.transactionList?.outflow?.items
    } else {
      outflowDataList = props.transactionList?.outflow?.items?.slice(outflowCount - range.value, outflowCount)
    }
    outSerieData = outflowDataList.map((item: any) => {
      return { value: item.value, ...item }
    })
    outflowMinValueObj = minBy(outSerieData, 'value')
    // eslint-disable-next-line no-unused-vars
    outflowMinValue =
      // eslint-disable-next-line no-unsafe-optional-chaining
      outflowMinValueObj?.value > 0 ? outflowMinValueObj?.value - 2 : outflowMinValueObj?.value + 2
    series = [
      {
        name: t('report.inflow'),
        data: inSerieData,
        type: 'bar',
        areaStyle: { opacity: 0.1 },
        smooth: false,
        emphasis: { focus: 'series' },
        barMaxWidth: 40,
        showSymbol: false,
        yAxisIndex: 0
      },
      {
        name: t('report.outflow'),
        data: outSerieData,
        type: 'bar',
        areaStyle: { opacity: 0.1 },
        smooth: false,
        emphasis: { focus: 'series' },
        barMaxWidth: 40,
        showSymbol: false,
        yAxisIndex: 0
      }
    ]
    yAxisData = [
      {
        type: 'value',
        scale: true,
        offset: 10,
        // min: inflowMinValue,
        axisLabel: {
          show: true
        },
        splitLine: {
          show: true
        }
      },
      {
        type: 'value',
        scale: true,
        offset: 10,
        // min: outflowMinValue,
        axisLabel: {
          show: false
        },
        splitLine: {
          show: true
        },
        gridIndex: 0
      }
    ]
  }
  isEmpty = every(series, (item: any) => !item?.data?.length)
  const options = {
    title: isEmpty
      ? { text: t('common.noData'), left: 'center', top: 'middle' }
      : {
          show: props.type === 'Balance',
          textStyle: {
            color: '#636B75',
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 15
          },
          text: unit.value === 'individuals' ? 'USD' : t('common.reportUnit', { unit: t(`common.${unit.value}`) }),
          left: '25px'
        },
    color: chartColor,
    legend: {
      data:
        props.type === 'Transactions'
          ? [
              {
                icon: 'circle',

                name: t('report.inflow')
              },
              { icon: 'circle', name: t('report.outflow') }
            ]
          : [],
      itemWidth: 14, // 设置图例项的宽度，与折线图的样式相匹配
      itemHeight: 18,
      itemGap: 37,
      lineStyle: {
        width: 2,
        type: 'solid',
        cap: 'round',
        join: 'round'
      },

      left: '26px'
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        let formatParams = `<div class="elv-barChart-tooltip">
          <p class="elv-barChart-tooltip__title">${dayjs(params[0].axisValue).format('YYYY/MM/DD')}</p>`

        for (let i = 0; i < params.length; i += 1) {
          formatParams += `
          <div>
              <p>${params[i].seriesName}</p>
              <span>${
                props.type === 'Balance'
                  ? utils.fieldValueFormat(params[i].value, { dollar: true }, 'NUMBER')
                  : params[i].value
              }</span>
            </div>
         `
        }
        formatParams += '</div>'
        return formatParams
      }
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      offset: 10,
      axisLine: {
        show: false // 不显示坐标轴线
      },
      axisTick: { show: false }, // 不显示坐标轴刻度线
      axisLabel: {
        // 坐标轴标签样式设置
        formatter(value: string | number | Date) {
          return dayjs(value).format('MMM DD')
        }
      }
    },
    yAxis: yAxisData,
    series,
    grid: { left: 40, top: 48, right: 10, bottom: 24, containLabel: true },
    graphic: {
      type: 'image',
      z: -1,
      left: 'center',
      top: 'middle',
      style: {
        image: waterMark,
        width: 530,
        height: 175
      }
    }
  }
  return options
})

const handleRangeClick = (val: string | number | boolean) => {
  switch (val) {
    case '7D':
      range.value = 7
      break
    case '30D':
      range.value = 30
      break
    case '90D':
      range.value = 90
      break
    default:
      range.value = 180
      break
  }
}

const resizeHandler = () => {
  const parentContainer = document.getElementById(`elv-dashboard-bar-chart-content-chart__${props.type}`)!
  const rect = parentContainer?.getBoundingClientRect()
  const chartWidth = rect?.width // 获取父元素的宽度
  const chartHeight = rect?.height // 获取父元素的高度
  chart?.resize({ width: chartWidth, height: chartHeight })
}

const setChart = () => {
  const parentContainer = document.getElementById(`elv-dashboard-bar-chart-content-chart__${props.type}`)!
  const chartDom = document.getElementById(`bar-chart-${props.type}`)!
  if (chartDom && parentContainer) {
    chart = echarts.init(chartDom, {
      useDirtyRect: true
    })
    resizeHandler()
    chart?.setOption(option.value)
    window.addEventListener('resize', resizeHandler)
  }
}

const killEchart = () => {
  window.removeEventListener('resize', resizeHandler)
  chart?.dispose()
}

watch(
  [() => rangeCurrent.value, () => props.marketValueList, () => props.transactionList],
  () => {
    if (props.guideStep > 2) {
      nextTick(() => {
        setChart()
      })
    }
  },
  {
    immediate: true,
    deep: true
  }
)

// 销毁chart
onBeforeUnmount(() => {
  killEchart()
})
</script>

<style lang="scss">
.elv-dashboard-bar-chart-container {
  width: 100%;
  height: 488px;
  box-sizing: border-box;
  padding: 20px 0 13px 0;
  // margin-left: 4px;
  background: #f9fafb;
  border-radius: 5px;

  .elv-dashboard-bar-chart-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;

    .elv-dashboard-bar-chart-header__title {
      font-family: 'Plus Jakarta Sans';
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      color: #0e0f11;
      margin-left: 24px;
    }

    .elv-chart-tabs {
      border-radius: 30px;
      padding-right: 20px;

      .el-radio-button {
        --el-radio-button-checked-bg-color: #edf0f3;
        --el-radio-button-checked-border-color: #dde1e6;

        --el-radio-button-checked-text-color: #636b75;
        --el-button-text-color: #d0d4d9;

        &:first-child .el-radio-button__inner {
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
        }

        &:last-child .el-radio-button__inner {
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
        }

        .el-radio-button__inner {
          padding: 7px 8px;
          border-color: #dde1e6;
          background-color: #f9fafb;
          color: #838d95;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 17px;
        }

        &.is-active .el-radio-button__inner {
          background-color: #fff;
          color: #0e0f11;
        }

        &:not(.is-active):hover .el-radio-button__inner {
          background-color: #fff;
          color: #0e0f11;
        }
      }
    }
  }

  .elv-dashboard-bar-chart-content-chart {
    width: 100%;
    height: 420px;

    > div {
      width: auto;
      height: auto;
    }
  }
}

.elv-barChart-tooltip {
  .elv-barChart-tooltip__title {
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: #636b75;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;

    p {
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      color: #636b75;
    }

    span {
      font-family: 'Barlow';
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      color: #0e0f11;
      margin-left: 32px;
      text-align: right;
    }
  }
}
</style>

<template>
  <el-row class="elv-dashboard-assets-container" justify="space-between">
    <el-col :span="16">
      <div class="elv-dashboard-assets-left">
        <div class="elv-dashboard-assets-left-title">{{ t('report.Assets') }}</div>
        <el-table :data="tableData" class="elv-dashboard-assets-table" height="343px" :empty-text="t('common.noData')">
          <el-table-column :label="t('common.priority')">
            <template #header> {{ props.holdingValues.length }} {{ t('report.assets') }}</template>
            <template #default="{ row }">
              <div class="elv-dashboard-assets-table-row-asset">
                <img :src="currentIcon(row.currency)?.logo" :alt="currentIcon(row.currency)?.name" />
                <p>{{ currentIcon(row.currency)?.name }}</p>
                <span>{{ currentIcon(row.currency)?.symbol }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="quantity" :label="t('report.Quantity')" align="right">
            <template #default="{ row }">
              {{ utils.formatNumber(row.quantity, 2) }}
            </template>
          </el-table-column>
          <el-table-column prop="marketValue" :label="t('report.Fair Value')" align="right">
            <template #default="{ row }">
              {{ utils.fieldValueFormat(row.marketValue, { dollar: true }, 'NUMBER') }}
            </template>
          </el-table-column>
        </el-table>
        <div
          v-if="props.holdingValues.length && props.holdingValues.length > 7"
          class="elv-dashboard-assets-more"
          @click="onJumpHoldingPage"
        >
          <SvgIcon name="arrow-right-line" width="16" height="16" />
          {{ t('report.Significant holdings report') }}
        </div>
      </div>
    </el-col>
    <el-col :span="8">
      <div id="elv-dashboard-assets-right">
        <div class="elv-dashboard-assets-right-title">{{ t('report.assetsPortfolio') }}</div>
        <div id="dashboard-doughnut-chart" />
      </div>
    </el-col>
  </el-row>
</template>

<script setup lang="ts">
import { find } from 'lodash-es'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import echarts from '@/plugins/echarts'
import { HoldingValueType } from '#/ReportsTypes'
import { useReportStore } from '@/stores/modules/reports/index'

const props = defineProps({
  holdingValues: {
    type: Array<HoldingValueType>,
    default: () => []
  },
  guideStep: {
    type: Number,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const reportStore = useReportStore()

let chart: echarts.ECharts | null = null

const tableData = computed(() => {
  return props.holdingValues.slice(0, 7)
})

const currentIcon: any = computed(() => {
  return (currency: string) => {
    return find(reportStore.currencyList, { symbol: currency })
  }
})

const onJumpHoldingPage = () => {
  router.push({
    name: 'reports-significant-holdings',
    params: {
      entityId: route.params.entityId
    }
  })
}

const resizeHandler = () => {
  const parentContainer = document.getElementById(`elv-dashboard-assets-right`)!
  const chartWidth = parentContainer.offsetWidth - 50 // 获取父元素的宽度
  const chartHeight = parentContainer.offsetHeight - 68 // 获取父元素的高度
  chart?.resize({ width: chartWidth, height: chartHeight })
}

const setChart = () => {
  const seriesData: any = props.holdingValues.slice(0, 5).map((item) => {
    return {
      value: Math.abs(item?.marketValue), // 使用 marketValue 的绝对值作为展示值
      name: item?.currency,
      ...item
    }
  })
  if (props.holdingValues.length > 5) {
    const otherQuantity = props.holdingValues.slice(5).reduce((total, item) => {
      return total + Math.abs(item?.quantity)
    }, 0)
    const otherMarketValue = props.holdingValues.slice(5).reduce((total, item) => {
      return total + Math.abs(item?.marketValue)
    }, 0)
    const otherCostBasis = props.holdingValues.slice(5).reduce((total, item) => {
      return total + Math.abs(item?.costBasis)
    }, 0)
    seriesData.push({
      value: otherMarketValue,
      name: 'Other',
      quantity: otherQuantity,
      marketValue: otherMarketValue,
      costBasis: otherCostBasis,
      currency: 'Other'
    })
  }
  const parentContainer = document.getElementById(`elv-dashboard-assets-right`)!
  const chartDom = document.getElementById(`dashboard-doughnut-chart`)!
  if (chartDom && parentContainer) {
    chart = echarts.init(chartDom, {
      useDirtyRect: true
    })
    const lineOption = {
      title: {
        show: !seriesData.length, // 没数据才显示
        extStyle: {
          color: 'gray',
          fontSize: 20
        },
        text: t('common.noData'),
        left: 'center',
        top: 'center'
      },
      series: [
        {
          name: t('report.assetsPortfolio'),
          type: 'pie',
          radius: ['50%', '60%'], // 设置内外半径，控制圆环的宽度
          center: ['50%', '50%'],
          label: {
            show: true,
            position: 'outside',
            alignTo: 'edge',
            edgeDistance: 0,
            formatter(params: any) {
              return `${params.name}\n${params.percent}%`
            },
            color: '#000',
            fontWeight: 700,
            fontFamily: 'Plus Jakarta Sans',
            fontSize: 12
          },
          labelLine: {
            length: 12, // 调整视觉引导线的长度
            length2: 2,
            lineStyle: {
              color: '#000'
            }
          },
          data: seriesData
        },
        {
          name: 'Assets Portfolio',
          type: 'pie',
          radius: ['50%', '60%'], // 设置内外半径，控制圆环的宽度
          center: ['50%', '50%'],
          emphasis: {
            label: {
              show: true
            }
          },
          label: {
            show: false,
            position: 'center',
            formatter(params: any) {
              return [
                `{a|${currentIcon.value(params.data?.currency)?.name ?? params.data?.currency}}`,
                `{b|}`,
                `{c|${utils.formatNumber(params.data?.quantity, 2)}}`,
                `{d|${utils.fieldValueFormat(params.data?.marketValue, { dollar: true }, 'NUMBER')}}`
              ].join('\n')
            },
            rich: {
              a: {
                fontWeight: 600,
                lineHeight: 23,
                fontSize: 18,
                align: 'center',
                color: '#1E2024',
                fontFamily: 'Plus Jakarta Sans',
                padding: [0, 0, 9, 0]
              },
              b: {
                width: 50,
                height: 1,
                align: 'center',
                backgroundColor: '#DDE1E6'
              },
              c: {
                fontWeight: 500,
                lineHeight: 19,
                fontSize: 16,
                align: 'center',
                color: '#1E2024',
                fontFamily: 'Barlow',
                padding: [10, 0, 0, 0]
              },
              d: {
                fontWeight: 500,
                lineHeight: 14,
                fontSize: 12,
                align: 'center',
                color: '#1E2024',
                fontFamily: 'Barlow',
                padding: [14, 0, 0, 0]
              }
            }
          },
          data: seriesData
        }
      ]
    }
    chart.setOption(lineOption)
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
  }
}

const killEchart = () => {
  window.removeEventListener('resize', resizeHandler)
  chart?.dispose()
}

watch(
  route,
  async (newValue) => {
    if (newValue.name === 'reports-dashboard') {
      if (props.guideStep > 2) {
        nextTick(() => {
          setChart()
        })
      }
    }
  },
  { immediate: true, deep: true }
)

// 销毁chart
onBeforeUnmount(() => {
  killEchart()
})
</script>

<style lang="scss">
.elv-dashboard-assets-container {
  display: flex;
  justify-content: space-between;
  height: 466px;
  box-sizing: border-box;
  margin: 16px 0;
  border-radius: 5px;

  .el-col {
    padding: 0px !important;
  }

  .elv-dashboard-assets-left,
  #elv-dashboard-assets-right {
    height: 466px;
    border-radius: 5px;
    background: #f9fafb;
  }

  .elv-dashboard-assets-left {
    width: calc(100% - 16px);
    padding: 23px 24px 30px;
    box-sizing: border-box;
  }

  .elv-dashboard-assets-left-title {
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 19px;
  }

  .elv-dashboard-assets-table {
    background: #f9fafb;

    .el-table__cell,
    .cell {
      padding: 0px;
    }

    .el-table__cell {
      border-color: #edf0f3;
      background: #f9fafb;
    }

    .el-table__header {
      .el-table__cell {
        padding-bottom: 9px;
        border-color: #dde1e6;

        .cell {
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }
      }
    }

    .el-table__row {
      &:hover {
        .el-table__cell {
          background: #f9fafb;
        }
      }

      .el-table__cell {
        height: 44px;
        padding: 8px 0px;

        .cell {
          font-family: 'Barlow';
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          color: #1e2024;
        }
      }
    }

    .elv-dashboard-assets-table-row-asset {
      display: flex;
      align-items: center;

      img {
        width: 28px;
        height: 28px;
        display: block;
      }

      p {
        margin: 0 8px;
        font-family: 'Plus Jakarta Sans';
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #1e2024;
      }

      span {
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #aaafb6;
      }
    }
  }

  .elv-dashboard-assets-more {
    display: flex;
    align-items: center;
    margin-top: 16px;
    cursor: pointer;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-decoration: underline;
    color: #1e2024;

    svg {
      margin-right: 13px;
    }
  }

  #elv-dashboard-assets-right {
    box-sizing: border-box;
    padding: 23px 25px;

    .elv-dashboard-assets-right-title {
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      // margin-bottom: 49px;
    }

    #dashboard-doughnut-chart {
      width: auto;
      height: auto;
    }
  }
}
</style>

<template>
  <GuideStep v-if="reportStore.entityDetail?.guideStep < 3" type="DASHBOARD" />
  <el-scrollbar v-else v-loading.fullscreen.lock="loading" class="elv-dashboard-container">
    <template v-if="!loading">
      <el-row :gutter="8" class="elv-dashboard-line-chart-container">
        <el-col :span="6"
          ><LineChart
            type="Balance"
            :title="t('common.balance')"
            :guide-step="reportStore.entityDetail?.guideStep"
            :value="dashboardDetail?.marketValueList?.[dashboardDetail?.marketValueList.length - 1]?.value ?? 0"
            :chart-data="dashboardDetail?.marketValueList"
            line-color="rgba(23, 83, 235,1)"
        /></el-col>
        <el-col :span="6"
          ><LineChart
            type="Revenue"
            :title="t('common.90D Revenue')"
            :guide-step="reportStore.entityDetail?.guideStep"
            :value="dashboardDetail?.revenueList?.[dashboardDetail?.revenueList.length - 1]?.value ?? 0"
            :chart-data="dashboardDetail?.revenueList"
            line-color="rgba(164, 216, 0, 1)"
        /></el-col>
        <el-col :span="6"
          ><LineChart
            type="Expense"
            :title="t('common.90D Expense')"
            :guide-step="reportStore.entityDetail?.guideStep"
            :value="dashboardDetail?.expenseList?.[dashboardDetail?.expenseList.length - 1]?.value ?? 0"
            :chart-data="dashboardDetail?.expenseList"
            line-color="rgba(223, 34, 0, 1)"
        /></el-col>
        <el-col :span="6"
          ><LineChart
            type="Profit"
            :title="t('common.90D Profit')"
            :guide-step="reportStore.entityDetail?.guideStep"
            :value="dashboardDetail?.profitList?.[dashboardDetail?.profitList.length - 1]?.value ?? 0"
            :chart-data="dashboardDetail?.profitList"
            line-color="rgba(230, 139, 47, 1)"
        /></el-col>
      </el-row>

      <BarChart
        type="Balance"
        :guide-step="reportStore.entityDetail?.guideStep"
        :market-value-list="dashboardDetail?.marketValueList"
      />

      <AssetsChart :guide-step="reportStore.entityDetail?.guideStep" :holding-values="dashboardDetail?.holdingValues" />

      <BarChart
        type="Transactions"
        :guide-step="reportStore.entityDetail?.guideStep"
        :transaction-list="dashboardDetail?.transactionList"
      />
    </template>
  </el-scrollbar>
</template>

<script setup lang="ts">
// import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'
import BarChart from './components/BarChart.vue'
import LineChart from './components/LineChart.vue'
import { DashboardDataType } from '#/ReportsTypes'
import GuideStep from '../components/GuideStep.vue'
import AssetsChart from './components/AssetsChart.vue'
import { useReportStore } from '@/stores/modules/reports/index'

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()

const loading = ref(false)
const dashboardDetail = ref<DashboardDataType>()

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

watch(
  route,
  async (newValue) => {
    try {
      if (newValue.name === 'reports-dashboard') {
        loading.value = true
        reportStore.checkTransactionsAll = false
        reportStore.batchEditSelected = []
        const { data } = await ReportsApi.getDashboardDetail(entityId.value)
        dashboardDetail.value = data
        loading.value = false
      }
    } catch (error: any) {
      console.log(error)
      ElMessage.error(error.message)
    } finally {
      loading.value = false
    }
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss">
.elv-dashboard-container {
  width: 100%;
  height: calc(100vh - 47px);
  background-color: #fff;
  padding: 16px 22px 26px 16px;
  box-sizing: border-box;

  .el-scrollbar__view {
    height: 100%;
    width: 100%;

    .elv-dashboard-line-chart-container.el-row {
      margin-bottom: 16px;

      .el-col {
        border-radius: 5px;
      }
    }
  }
}
</style>

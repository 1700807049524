<template>
  <div class="elv-dashboard-line-chart-content">
    <div class="elv-dashboard-line-chart-content-title">{{ props.title }}</div>
    <div class="elv-dashboard-line-chart-content-value">
      {{ utils.fieldValueFormat(props.value, { dollar: true }, 'NUMBER') }}
    </div>
    <template v-if="props.type === 'Balance'">
      <div
        v-if="props.chartData.length"
        :id="`elv-dashboard-line-chart-content-chart__${props.type}`"
        class="elv-dashboard-line-chart-content-chart"
      >
        <div :id="`line-chart-${props.type}`" />
      </div>
      <div
        class="elv-dashboard-line-chart-quota"
        :class="[percentContent.balanceDiff >= 0 ? 'is-increase' : 'is-decrease']"
      >
        <SvgIcon name="dashboard-quota" width="16" height="16" />
        <p>
          {{ percentContent.balanceDiffPercent ? percentContent.balanceDiffPercent : '0%' }}
        </p>
        <span
          >({{ percentContent.balanceDiff >= 0 ? '+' : '-' }}{{ utils.formatNumber(percentContent.balanceDiff) }})</span
        >
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import utils from '@/lib/utils'
import { LineChartDataType } from '#/ReportsTypes'
import echarts from '@/plugins/echarts'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    required: true
  },
  value: {
    type: Number,
    default: 0
  },
  chartData: {
    type: Array<LineChartDataType>,
    default: () => []
  },
  lineColor: {
    type: String,
    default: '#1753EB'
  },
  guideStep: {
    type: Number,
    required: true
  }
})

const route = useRoute()

let chart: echarts.ECharts | null = null

const percentContent = computed(() => {
  if (!props.chartData?.length) {
    return {
      balanceDiff: 0,
      balanceDiffPercent: 0
    }
  }
  const balance = props.chartData[props.chartData.length - 1].value
  const balance2 = props.chartData[props.chartData.length - 2].value
  const balanceDiff = balance - balance2
  const balanceDiffPercent = utils.toPercent(balanceDiff / balance2, 2)
  return {
    balanceDiff,
    balanceDiffPercent
  }
})

const resizeHandler = () => {
  const parentContainer = document.getElementById(`elv-dashboard-line-chart-content-chart__${props.type}`)!
  const rect = parentContainer?.getBoundingClientRect()
  const chartWidth = rect?.width // 获取父元素的宽度
  const chartHeight = rect?.height // 获取父元素的高度
  chart?.resize({ width: chartWidth, height: chartHeight })
}

const setChart = () => {
  const timeData = props.chartData.map((item: any) => item.date)
  const valData = props.chartData.map((item: any) => item.value)
  const chartDom = document.getElementById(`line-chart-${props.type}`)!
  const parentContainer = document.getElementById(`elv-dashboard-line-chart-content-chart__${props.type}`)!
  if (chartDom && parentContainer) {
    chart = echarts.init(chartDom, {
      useDirtyRect: true
    })
    const lineOption = {
      xAxis: {
        type: 'category',
        data: timeData,
        axisLabel: { show: false },
        axisTick: { show: false }, // 隐藏刻度线
        axisLine: { show: false }, // 隐藏横轴线
        splitLine: { show: false } // 隐藏纵向背景网格线
      },
      yAxis: {
        type: 'value',
        data: valData,
        axisLabel: { show: false },
        axisTick: { show: false }, // 隐藏刻度线
        axisLine: { show: false }, // 隐藏纵轴线
        splitLine: { show: false } // 隐藏纵向背景网格线
      },
      series: [
        {
          type: 'line',
          sampling: 'lttb',
          itemStyle: {
            color: props.lineColor
          },
          lineStyle: {
            width: 2
          },
          data: valData,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: props.lineColor
              },
              {
                offset: 1,
                // eslint-disable-next-line prefer-template
                color: props.lineColor.substring(0, props.lineColor.length - 2) + '0)'
              }
            ])
          }
        }
      ],
      grid: { left: '5%', right: '5%', top: '8%', bottom: '2%', containLabel: true }
    }
    chart.setOption(lineOption)
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
  }
}
const killEchart = () => {
  window.removeEventListener('resize', resizeHandler)
  chart?.dispose()
}

watch(
  route,
  async (newValue) => {
    if (newValue.name === 'reports-dashboard' && props.guideStep > 2) {
      nextTick(() => {
        if (props.type === 'Balance') {
          setChart()
        }
      })
    }
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  killEchart()
})
</script>

<style lang="scss" scoped>
.elv-dashboard-line-chart-content {
  width: 100%;
  height: 187px;
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  border-radius: 5px;
  background: #f9fafb;

  .elv-dashboard-line-chart-content-title {
    color: #648ca1;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  .elv-dashboard-line-chart-content-value {
    color: #0e0f11;
    font-family: 'Barlow';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }

  .elv-dashboard-line-chart-content-chart {
    margin-top: 8px;
    width: calc(100% - 54px);
    box-sizing: border-box;
    height: 44px;
    min-width: 188px;

    > div {
      width: auto;
      height: auto;
    }
  }

  .elv-dashboard-line-chart-quota {
    display: flex;
    align-items: center;
    font-family: 'Barlow';
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    margin-top: 8px;

    p {
      margin: 0 5px;
    }

    &.is-increase {
      color: #1ba742;

      svg {
        fill: #1ba742;
      }
    }

    &.is-decrease {
      color: #e60000;

      svg {
        fill: #e60000;
        transform: rotate(180deg);
      }
    }
  }
}
</style>
